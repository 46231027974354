import axios from 'axios';

const BACKEND_URL = `${process.env.REACT_APP_API_URL}`

const ClienteAxios = axios.create({
    baseURL : BACKEND_URL
});

export default ClienteAxios;

//baseURL : 'http://localhost:8000/v1/auth'
//baseURL : 'https://api.rp.gob.pa'
//baseURL : 'http://localhost:5000'