import ClienteAxios from '../config/axios';


export const Confirmar = async (id:string | undefined) => {
    let temp:any = []
    let response = { statusresponse: false,message:'', data: temp };
    try {
        const res = await ClienteAxios(`/confirmarCuenta/${id}`);
        response.message = res.data.message;
        response.statusresponse = res.data.success;
    } catch (err) {
     //  console.log(err) 
      // response.statusresponse = true;
      // response.message = err.response.data.message || 'Información incorrecta'
       response.message =  'Información incorrecta'
    }
    return response;
};

export const restablecer = async (token:string | undefined) => {
    let temp:any = []
    let response = { statusresponse: false,message:'', data: temp };

    try {
        const res = await ClienteAxios(`/restablecerpassword/${token}`);
        response.data = res.data;
        response.statusresponse = res.data.success;
    } catch (err) {
     //  console.log(err) 
      // response.statusresponse = true;
      // response.message = err.response.data.message || 'Información incorrecta'
       response.message =  'Información incorrecta'
    }
    return response;
};

export const actualizarpassword = async (options:any) => {
    let temp:any = []
    let response = { statusresponse: false,message:'', data: temp };

    try {
        const res = await ClienteAxios.post('/actualizarpassword',options);
        response.data = res.data;
        response.statusresponse = res.data.success;
    } catch (err) {
     //  console.log(err) 
      // response.statusresponse = true;
      // response.message = err.response.data.message || 'Información incorrecta'
       response.message =  'Información incorrecta'
    }
    return response;
};


