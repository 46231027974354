import React, {Fragment,useEffect,useState} from 'react';
import {  useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { restablecer,actualizarpassword } from '../utils/acciones';

function Restablecerpassword(){
    const { token } = useParams();

    const [visible, setVisible] = useState(false);


    const[datosCriterio, guardarCriterio] = useState({
        correo : '',
        password : '',
        conf_password : ''
    });


    // iniciar sesión en el servidor
    const consultarAPI = async () => {
        let response = await restablecer(token)
       // console.log(response)
        if (response.statusresponse){
            setVisible(true)
            await guardarCriterio({
                ...datosCriterio,
                correo : response.data.correo
            })
           
        }else{
            Swal.fire({
                title: 'Estimado Usuario',
                text: response.data.message,
                icon: 'info',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.value) {
                    setVisible(false)
                    //window.location.href = 'https://registro-publico.gob.pa/'
                }
              })
             return

           
        }
    }


    const actualizarcontrasena = async () => {
        let response = await actualizarpassword(datosCriterio)
       // console.log(response)
        if (response.statusresponse){
           
            Swal.fire({
                title: 'Felicidades',
                text: response.data.message,
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.value) {
                  //  window.location.href = 'https://registro-publico.gob.pa/'
                }
              })
             return
           
        }else{
            Swal.fire({
                title: 'Estimado Usuario',
                text: response.data.message,
                icon: 'info',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.value) {
                    //setVisible(false)
                    //window.location.href = 'https://registro-publico.gob.pa/'
                }
              })
             return

           
        }
    }

    // almacenar lo que el usuario escribe en el state
    const leerDatos = (e:React.ChangeEvent<HTMLInputElement>) => {
            guardarCriterio({
                ...datosCriterio,
                [e.target.name] : e.target.value
            })
    }

    const validarCriterio = (e:React.FormEvent<HTMLFormElement>)=> {
        e.preventDefault();
        // Destructuring
        console.log(datosCriterio)
        const { password, conf_password } = datosCriterio;
        

        // revisar que las propiedades del state tengan contenido
        let valido = !password.length || !conf_password.length;
        if (valido) {
            Swal.fire({
                icon: 'warning',
                title: 'Error',
                text: 'Todos los campos son obligatorios'
            })
        }else{
            if (password !== conf_password) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Error',
                    text: 'Las contraseñas no son iguales'
                })
            }else{
                
              actualizarcontrasena()  
            }
           
        }
    }

    // cuando el componente carga
    useEffect(() => {
        consultarAPI();
    })
    


    return(
        <>   
        { !visible ?
            (
                <>   
                  <div className="auth-page">

                  </div>
                </>              
            )
			:
			(
                <>                            
                <div className="auth-page">
                <div className="card auth-card shadow-lg">
                    <div className="card-body">
                        <div className="px-1">
                            <div className="auth-logo-box">
                                <a href="../analytics/analytics-index.html" className="logo logo-admin"><img src="../assets/images/logo-sm.png"  alt="logo" className="auth-logo"/></a>
                            </div>
                            
                            <div className="text-center auth-logo-text">
                                <h4 className="mt-0 mb-3 mt-5">Registro Público de Panamá</h4>
                                <h5 className="mt-0 mb-1 mt-0">Cambiar contraseña</h5>
                                <p className="text-muted mb-0">Crea una contraseña nueva que tenga al menos 8 caracteres.</p>  
                            </div>

                            
                            <form 
                            className="form-horizontal auth-form my-4" 
                            onSubmit={(e) =>validarCriterio(e) }
                            >


                                <div className="form-group">
                                    <label htmlFor="userpassword"></label>                                            
                                    <div className="input-group mb-3"> 
                                        <span className="auth-form-icon">
                                            <i className="dripicons-lock"></i> 
                                        </span>                                                       
                                        <input 
                                        type="password" 
                                        name="password"
                                        className="form-control" 
                                        placeholder="Contraseña nueva"
                                        onChange={leerDatos}
                                        />
                                    </div>                               
                                </div>
                                
                                <div className="form-group">
                                    <label htmlFor="conf_password"></label>                                            
                                    <div className="input-group mb-3"> 
                                        <span className="auth-form-icon">
                                            <i className="dripicons-lock-open"></i> 
                                        </span>                                                       
                                        <input 
                                        type="password"
                                        name="conf_password"
                                        className="form-control" 
                                        placeholder="Repetir contraseña"
                                        onChange={leerDatos}
                                        />
                                    </div>                               
                                </div> 



                                <div className="form-group mb-0 row">
                                    <div className="col-12 mt-2">
                                        <button className="btn btn-gradient-primary btn-round btn-block waves-effect waves-light" type="submit">Cambiar </button>
                                    </div>
                                </div>                          
                            </form>
                        </div>
                        
                    </div>
                </div>
            </div>
            </>
        )
    }
    </>        

    )

}

export default Restablecerpassword;