import React from 'react';
import { Routes, Route, Link } from "react-router-dom"
import Restablecerpassword from './pages/Restablecerpassword';
import ConfirmarCuenta from './pages/ConfirmarCuenta';
import Inicio from './pages/inicio';




function App() {
  return (

     <div className="row vh-100 ">
        <div className="col-12 align-self-center">
        <Routes>
           <Route path="/:id" element={<ConfirmarCuenta />} />
            <Route path="/restablecerpassword/:token" element={<Restablecerpassword />} />
            <Route path="/confirmarcuenta/:id" element={<ConfirmarCuenta />} />
        </Routes>

        </div>
     </div>  

  );
}



export default App;
