import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Confirmar } from '../utils/acciones';


function ConfirmarCuenta(){
    const { id } = useParams();

    const consultarAPI = async () => {
        //console.log(id) 
        let response = await Confirmar(id)
        if (response.statusresponse){
            Swal.fire({
                title: 'Felicidades',
                text: response.message,
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.value) {
                    window.location.href = 'https://registro-publico.gob.pa/'
                }
              })
             return
        }else{
            Swal.fire({
                title: 'Estimado Usuario',
                text: response.message,
                icon: 'info',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.value) {
                    window.location.href = 'https://registro-publico.gob.pa/'
                }
              })
             return

           
        }

    }

    // cuando el componente carga
    useEffect(() => {
        consultarAPI();
    })

    return(
        <div className="auth-page">

        </div>
    )

}

export default ConfirmarCuenta;